.app {
  max-width: 440px;
  scrollbar-width: none; /* Firefox */
}

/* 隐藏滚动条 */
.app::-webkit-scrollbar {
  display: none;
}

.card1 {
  height: auto;
  background: url("https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-16_imagewebp?alt=media&token=0fd5562c-3016-48ee-bc0d-57a0d01c70ca");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
}

.logo {
  display: flex;
  gap: 12px;
  font-family: "Sansita One";
  padding: 15px 13px;
  font-size: 18px;
  position: relative;
  right: 50%;
  transform: translateX(50%);
}

.over18 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: rgba(104, 0, 104, 0.8);
  margin-top: 68px;
  gap: 35px;
  width: 323px;
  height: 186px;
  border-radius: 8px;
  margin-bottom: 63px;
}

.yearsold {
  text-align: center;
  font-family: Cabin;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn-container {
  display: inline-flex;
  gap: 15px;
  font-size: 16px;
  text-align: center;
  font-family: Cabin;
}

.no {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 52px;
  border-radius: 56px;

  border: 1px solid #b107b1;
  background: #691467;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.yes {
  background: linear-gradient(270deg, #b107b1 0.16%, #b107b1 98.95%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25),
    0px 1px 1.3px 0px rgba(255, 255, 255, 0.25) inset;
}

.mouth {
  position: absolute;
  bottom: -24px;
  right: -16px;
}

.find {
  width: auto;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #b107b1;
}

.find p {
  color: #ff97ff;
  text-align: center;
  font-family: "Sansita One";
  font-size: 16px;
  margin: 0 7px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card2 {
  background: url("https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-15_imagewebp?alt=media&token=b3849709-aeee-480e-9511-afaa62cdd77e");
  padding: 30px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-item {
  width: 220px !important;
  transform: translateX(130px);
  position: relative;
  display: flex !important;
  justify-content: center;
  padding: 0 30px;
  border: none; /* 去掉边框 */
  outline: none; /* 去掉轮廓线 */
  gap: 30px;
}

.carousel-item img {
  width: 225px;
  text-align: center;
}

.choose {
  padding: 9px 16px;
  font-size: 18px;
  position: absolute;
  bottom: 34px;
  color: #fff;
  text-align: center;
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 40px;
  border: 2px solid #fdcdfd;
  background: linear-gradient(47deg, #ef39f1 4.98%, #f1397b 96.86%);
  box-shadow: 0px 4px 16px 0px rgba(177, 7, 177, 0.5);
  cursor: pointer;
}

.card3 {
  max-width: 440px;
  background: url("https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-18_imagewebp?alt=media&token=5f1d6df0-7fba-41cd-8432-91f0d4884ab6");
  padding: 22px 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  position: relative;
}

.card3-top {
  display: inline-flex;
  align-items: center;
  margin-left: -10px;
  margin-bottom: 51px;
}

.chat-wrep {
  display: flex;
  gap: 5px;
  margin-bottom: 46px;

  flex-direction: column;
  justify-content: center;
}

.what {
  color: #fff;
  font-family: Cabin;
  font-size: 20px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chatmess {
  box-sizing: border-box;
  width: 171px;
  height: 82px;
  border-radius: 6px;
  padding: 7px 3px 0 8px;
  background: #fff;
}

.mess {
  color: #333;
  font-family: Cabin;
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  font-style: normal;
  line-height: normal;
}

.nickname {
  display: flex;
  gap: 5px;
  font-size: 12px;
  margin-top: -5px;
  color: #333;
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.download {
  position: absolute;
  background: #b107b1;
  color: #fff;
  text-align: center;
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 349px;
  height: 48px;
  bottom: 70px;
  border-radius: 10px;
  font-size: 16px;
}

.mask {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rest {
  position: relative;
  width: 313px;
  height: 325px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #691467;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.explain {
  color: #fff;
  text-align: center;
  font-family: Cabin;
  font-size: 18px;
  margin: 25px 25px 0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
